.body {
    margin: 0;
    font-family: 'Arial', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  }
 
  .comingSoon {
    text-align: center;
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
  }
  
  .image {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .title {
    font-size: 2.5rem;
    color: #343a40;
    margin: 1rem 0;
  }
  
  .text {
    font-size: 1.25rem;
    color: #6c757d;
  }
  
  .socialMedia {
    margin-top: 2rem;
  }
  
  .socialIcon {
    margin: 0 10px;
    font-size: 2rem;
    text-decoration: none;
  }

  .facebook {
    color: #3b5998;
  }
  
  .instagram {
    color: #e4405f;
  }
  
  .twitter {
    color: #55acee;
  }
  
  .youtube {
    color: #cd201f;
  }
  
  .clinicLink {
    display: inline-block;
    margin-top: 2rem;
    font-size: 1.25rem;
    color: #007bff;
    text-decoration: none;
  }
  
  .clinicLink:hover {
    text-decoration: underline;
  }
  
  .app {
    text-align: center;
    padding: 2rem;
  }
  