.hero {
  position: relative;
}
.hero-text {
  position: absolute;
  top: 60px;
  left: 120px;
}
.hero-text h1 {
  font-size: 4vw;
  line-height: 6vw;
  color: #000;
}
.hero-text h3 {
  font-size: 2vw;
  line-height: 40px;
  max-width: 60%;
}

@media screen and (max-width: 938px) {
  .hero-text {
    position: absolute;
    top: 32px;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 2;
  }
  .hero-text h1 {
    font-size: initial;
    line-height: 30px;
    color: #fff;
    text-align: center;
  }
  .hero-text h3 {
    font-size: initial;
    line-height: 30px;
    text-align: center;
    max-width: 100%;
    color: #fff;
  }

  .hero-text button span{
    color: white;
  }

  .hero img {
    height: 300px;
  }

  .hero::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
  }
}

.hero-divs {
  text-align: center;
}

.hero-divs .row > div {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.landing-doctors-description p {
  color: #434343;
  text-align: left;
  line-height: 28px;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
}
.landing-doctors-description h1 {
  font-size: 36px;
  color: #2d2d2d;
  line-height: 45px;
  text-align: left;
  font-weight: 800;
}
.landing-doctors-description h2 {
  font-size: 36px;
  color: #2d2d2d;
  line-height: 45px;
  text-align: center;
  line-height: 48px;
  letter-spacing: -1px;
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

.landing-doctors-description h3 {
  font-size: 20px;
  color: #383838;
  line-height: 26px;
  text-align: left;
  letter-spacing: 0px;
  font-weight: 800;
  font-style: normal;
}

.ldd * {
  color: white !important;
}

.landing-contact {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding-top: 144px !important;
  padding-bottom: 90px !important;
  background-position: 0 0 !important;
  background-repeat: no-repeat !important;
  display: block;
}

.contact-form {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.section-title {
  font-size: 36px;
  color: #2d2d2d;
  line-height: 45px;
  text-align: left;
  letter-spacing: -1px;
  font-weight: 800;
  font-style: normal;
}

.section-description {
  line-height: 28px;
  font-weight: 400;
  font-style: normal;
  color: #434343;
  font-size: 15px;
}

.contact-video {
  margin-top: 40px !important;
  margin-bottom: 0px !important;
  padding-top: 90px !important;
  padding-bottom: 50px !important;
  background-image: url(https://apexclinic.radiantthemes.com/clinic/wp-content/uploads/sites/6/2018/06/video-image.jpg?id=7882) !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.contact-video-text {
  font-size: 22px;
  color: #ffffff;
  line-height: 28px;
  text-align: center;
  font-weight: 800;
  padding-top: 65px;
  letter-spacing: 1.5px;
}

footer {
  padding: 62px 32px 0 32px;
  margin-top: 32px;
  color: white;
}

footer h3 {
  margin-bottom: 25px;
  font-weight: 700;
  font-size: 20px;
  color: #fff;
  line-height: 30px;
}

footer a {
  font-weight: 400;
  font-size: 15px;
  color: #fff;
  line-height: 26px;
}

ul li {
  list-style-type: none;
}

.rs-navbar {
  color: white;
}

.footer-bottom {
  display: flex;
  justify-content: space-around;
  padding-top: 16px;
  border-top: 1px solid lightgray;
}

.footer-bottom p {
  margin-top: 0;
  font-weight: 400;
}

.navbar {
  background-color: #14a09d;
  padding: 0 !important;
}


.navbar-nav .nav-item {
  color: white;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  text-transform: uppercase;
  line-height: 26px;
  font-weight: 500;
  font-style: normal;
  font-size: 13px;
  text-align: center;
  margin-right: 25px;
}

.navbar .nav-link {
  color: white;
}

.navbar-nav .nav-item::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: white;
  transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
}

.navbar-nav .nav-item:hover::before {
  width: 100%;
  left: 0;
}

.navbar .nav-item .navlink li a:hover {
  border-bottom: unset;
}
a:active,
a:focus,
a:hover {
  text-decoration: unset;
}

.hover-button {
  position: relative;
  padding: 8px 0;
  display: inline-block;
  border: 1px solid #14a09d;
  background: none;
  cursor: pointer;
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
  min-width: 200px;
}

.hover-button:hover {
  background-color: #14a09d;
  color: white;
}

.hover-button .text {
  display: inline-block;
  vertical-align: middle;
  font-weight: 500;
  font-size: 14px;
}

.hover-button svg {
  opacity: 0;
  visibility: hidden;
  margin-left: -15px;
  transition: all 0.3s ease-in-out;
  color: white;
}

.hover-button:hover svg {
  visibility: visible;
  opacity: 1;
  margin-left: 17px;
}

.doctor-container:hover .doctor-details {
  opacity: 1;
}
.doctor-details {
  color: white;
  margin-top: auto;
  background: #14a09d;
  text-align: center;
  padding: 16px;
  opacity: 0;
  transition: all 0.3s ease-in;
}

.doctor-details h3 {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.doctor-details p {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 1px;
}


.services svg{
  font-size: 40px;
}

.doctor ul li {
  list-style-type: disc;
}


@media screen and (max-width: 800px){
  .slick-dots{
    bottom: -50px;
  }
  .slick-prev, .slick-next {
    bottom: -30px!important;
    top: unset!important;
  }
  .slick-prev {
    left: 35%!important;
  }
  .slick-next{
    right: 35%!important;
  }

  .slick-prev:before, .slick-next:before {
    font-size: 40px!important;
  }
}

.navbar {
  position: relative;
  transition: top 0.3s;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.slick-prev:before, .slick-next:before {
  color: var(--primary-color)!important;
  font-size: 30px;
}