@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');
body * {
  font-family: "Rubik", sans-serif!important;
}

.footer-title{
  font-family: "Libre Baskerville", serif!important;
  text-transform: uppercase;
}


:root{
    --primary-color: #35acaa
}